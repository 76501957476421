<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.user.createNewUser") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewuser())"
          @reset.prevent="resetForm"
        >
          <!-- Employee -->
          <validation-provider
            #default="validationContext"
            name="funcionário"
            rules="required"
          >
            <b-form-group
              label="Funcionário / Prestador"
              label-for="employee"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.user.employeeProvider") }}
              </template>
              <v-select
                :change="getEmail()"
                v-model="fullNameSelected"
                :value="fullNameSelected"
                :options="employeeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required"
          >
            <b-form-group label-for="email">
              <template v-slot:label>
                {{ $t("Email") }}
              </template>
              <b-form-input
                disabled
                id="Name"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tasks.type") }}
              </template>
              <v-select
                v-model="userProfile"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
                disabled
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Group -->
          <validation-provider
            #default="validationContext"
            name="grupo"
            rules="required"
          >
            <b-form-group
              label="Grupo"
              label-for="group"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("Group") }}
              </template>
              <v-select
                v-model="userData.userGroup"
                :options="groupOptions"
                :reduce="(val) => val.value"
                :clearable="false"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Session Time -->
          <validation-provider #default="validationContext" rules="required">
            <b-form-group label-for="estimatedTime">
              <template v-slot:label>
                {{ $t("sessionTime") }}
              </template>
              <number-input
                v-model="sessionTime"
                center
                controls
                :min="0"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="senha"
            rules="required"
          >
            <b-form-group label="Senha" label-for="passWord">
              <template v-slot:label>
                {{ $t("message.user.password") }}
              </template>
              <b-form-input
                id="Name"
                v-model="userData.password"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import Vue from "vue";
import VueNumberInput from "@chenfengyuan/vue-number-input";
Vue.use(VueNumberInput);
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
    employeeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      fullNameSelected: "",
      employeeData: null,
      flatConfig: {
        dateFormat: "d/m/Y",
      },

      //Param
      paramData: store.state.user.paramData,

      userDataObj: store.state.user.userData,

      disabledButton: false,

      sessionTime: 0,

      userProfile: "Usuário",
    };
  },

  created() {
    axios
      .get(
        `${URL_API}employee/list-dto?page=0&sort=id,${this.paramData.listSort}&size=9999`,
        {
          headers: getHeader(this.userDataObj),
        }
      )
      .then((response) => {
        this.employeeData = response.data.content;
      });
  },

  methods: {
    submitNewuser() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          axios
            .get(`${URL_API}user-email/` + this.userData.email, {})
            .then((response) => {
              if (response.data === true) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$i18n.t("Error"),
                    icon: "XIcon",
                    variant: "danger",
                    text: `Usuário já criado com este e-mail`,
                  },
                });
                return false;
              } else {
                this.disabledButton = true;
                this.onSubmit();

                for (let i = 0; i < this.employeeData.length; i++) {
                  const element = this.employeeData[i].fullName;
                  if (this.fullNameSelected === element) {
                    this.userData.employeeId = this.employeeData[i].id;
                  }
                }

                this.userData.systemRole = "ROLE_USER";

                this.userData.sessionTime = this.sessionTime;

                axios({
                  method: "post",
                  url: `${URL_API}user/` + this.userData.email,
                  headers: getHeader(this.userDataObj),
                  data: this.userData,
                })
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: this.$i18n.t("message.toast.create"),
                        icon: "CheckIcon",
                        variant: "success",
                        text: `O Usuário ${this.fullNameSelected} foi criado com sucesso`,
                      },
                    });
                  })
                  .then(() => {
                    this.$root.$emit("newListUser", null);
                    this.disabledButton = false;
                  })
                  .catch((error) => {
                    this.disabledButton = false;
                    console.log(error);
                  });
              }
            });
        }
      });
    },

    getEmail() {
      if (this.employeeData != null) {
        for (let i = 0; i < this.employeeData.length; i++) {
          if (this.fullNameSelected === this.employeeData[i].fullName) {
            this.userData.email = this.employeeData[i].internalEmail;
          }
        }
      }
    },
  },

  setup(props, { emit }) {
    const blankuserData = {};

    const userData = ref(JSON.parse(JSON.stringify(blankuserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankuserData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-user-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
